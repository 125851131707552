import React from "react"
import { Box,
  Flex,
  Heading,
  Text,
  Button,
  ListItem,
  UnorderedList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react"
import { ImArrowRight } from "react-icons/im"
import StripeWrapper from "./StripeWrapper"
import { RichText } from "prismic-reactjs"

const OtherDonationAmountButton = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const donationAmount = props.value;

  const addCommaToAmount = (amount) => {
    console.log(amount)
    console.log(typeof(amount))
    if (isNaN(amount)) {
      return "";
    } else {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
  
  //const donationAmount = props.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

  
  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} size="xl" >
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader position="relative" zIndex="unset">Donation Amount: ${donationAmount}</ModalHeader> */}
          <ModalBody>
          <Box>
            <Heading as="h3" color="#3a2152" fontSize="1.75rem" mb="8">
              Donation Amount: ${props.value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Heading>
              <StripeWrapper amount={props.value} />
          </Box>
          </ModalBody>
          <ModalCloseButton />
        </ModalContent>
      </Modal>

    <Box my="4">
    <Box>
        {props?.donationTitle && (
        <Heading as="h3" fontWeight="400" color="gold.400" fontSize="3rem">
          {props.donationTitle}
        </Heading>
        )}
        </Box>
    <Flex flexDirection='row'
        w="100%"
        alignItems="center"
        justifyContent="space-between"
        >
          <Box>
          <Text
            fontFamily="Open Sans"
            fontWeight="600"
            fontSize="1.25rem"
            color="gray.700"
        >
         {props.suggestedAmount}            
        </Text>
      </Box>
      <Box justifySelf={{sm: 'flex-end'}}>
      <Button
          fontFamily="Open Sans"
          fontWeight="600"
          color="gold.400"
          fontSize="1.25rem"
          textTransform="uppercase"
          background="none"
          _hover={{ color: '#3a2152'}}
          value={props.value}
          onClick={onOpen}
          disabled={!donationAmount}
        >
            Donate ${addCommaToAmount(donationAmount)} Now&nbsp;<ImArrowRight />
        </Button>
      </Box>
    </Flex>
    <hr />
  </Box>
  </>
  )
}

export default OtherDonationAmountButton
