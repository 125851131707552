import React, { useState } from 'react'
import { Box,
        Text,
        Heading,
        Container,
        Input
     } from '@chakra-ui/react'
import DonationButton from './DonationButton'
import OtherDonationAmountButton from './OtherDonationAmountButton'
import { RichText } from 'prismic-reactjs'


const HomePageContent = (props) => {
    const [amount, setAmount] = useState();

   return (
<>
    <Container maxW="container.sm" py={10} centerContent>
     <Box
        mb={4}
        className="donate-copy"
        textAlign="center"
        fontSize="1.5rem"
        my="4"
        >
    <RichText render={props.pageContent} />
</Box>
            </Container>
    <Container maxW="container.sm">
    <Box>
        <Box>
            <Heading as="h3" fontWeight="400" color="gold.400" fontSize="3rem" mb="2">
            Baby Cub
            </Heading>
            <Box>
                <form>
                <Input
                    type="number"
                    name="amount"
                    placeholder="Enter amount $"
                    //{...register("amount")}
                    onChange={event => setAmount(parseInt(event.target.value))}
                />
                </form>
            </Box>
        </Box>
            <Box>
                <OtherDonationAmountButton
                    suggestedAmount="$1 - $99"
                    donationAmount={amount}
                    value={amount}
                />
            </Box>
    </Box>
        {props.document.donation_level.map((item, index) => {
            return (
                <DonationButton
                    key={index}
                    donationTitle={item.donation_title.text}
                    donationAmount={item.donation_amount}
                    rewards={item.rewards.richText}
                    value={item.donation_amount}
                />
            )
        })}
    <Box>
        <Box>
            <Heading as="h3" fontWeight="400" color="gold.400" fontSize="3rem" mb="2">
            Other Donation
            </Heading>
            <Box>
                <form>
                <Input
                    type="number"
                    name="amount"
                    placeholder="Enter amount $"
                    //{...register("amount")}
                    onChange={event => setAmount(parseInt(event.target.value))}
                />
                </form>
            </Box>
        </Box>
            <Box>
                <OtherDonationAmountButton
                    donationAmount={amount}
                    value={amount}
                />
            </Box>
    </Box>

    <Box mt="4" p="4" className='lower-page-content'>
        <RichText render={props.lowerPageContent} />
    </Box>
    </Container>

    
    </>
    )
}

export default HomePageContent