import * as React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Box, Text, Heading, Center } from '@chakra-ui/react'

import Seo from "../components/seo"
import DonatePageContent from '../components/DonatePage/DonatePageContent'
import { VscTriangleDown } from "react-icons/vsc"


const Donate = ({ data, location }) => {
  const pageLocation = location.pathname;

  const document = data.prismicStaticPage.data
  const backgroundImage = getImage(document.hero_image.localFile)

return (
  <Layout location={pageLocation}>
    <Seo title="Grizzy 399 Project" />
    <Box mt={{ base: '61px', lg: '32px' }} position="relative">
    <GatsbyImage image={backgroundImage} alt={document.hero_image.alt} />
    <Box position="absolute"
        bottom="85"
        z-index="100"
      >
        </Box>
          <Box position="absolute"
            bottom="0"
            w="100%"
            padding={{sm: '2', md: '4' }}
            backgroundColor="rgba(58, 33, 82, 0.7)"
          >
         {document.hero_image.copyright && <Box position="absolute" zIndex="100" bottom="74px">
           <Text textTransform='uppercase'
            p="5px"
            color="white"
            fontWeight="700"
            fontFamily="Open Sans"
            fontSize={{ base: '.5rem', md: '.75rem'}}
            >Photo: {document.hero_image.copyright}</Text>
         </Box>
      }
          <Heading as="h1"
            color="white"
            fontSize={{ sm: '2rem', md: '2.5rem', xl: '3.5rem'}}
            textAlign="center"
            fontWeight="600">
            {document.page_title.text}
          </Heading>
        </Box>
    </Box>

    <Box textAlign="center" my="8" mb="0">
            <Text fontFamily='Open Sans' fontWeight="600" color="grey.600" mb="0">
              SCROLL
              </Text>
            <Box><Center><VscTriangleDown color="grey.600" size="40" style={{textAlign: 'center'}} /></Center></Box>
          </Box>
    
    <DonatePageContent 
      pageContent={document.page_content.richText} 
      document={document}
      lowerPageContent={document.content_below_donation_levels.richText}
      />
  </Layout>
)
}

export const query = graphql`
{
  prismicStaticPage(uid: {eq: "donate"}) {
    _previewable
    uid
    data {
      page_title {
        text
      }
      hero_image {
        alt
        copyright
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 90, layout: FULL_WIDTH)
          }
        }
      }
      page_content {
        richText
      }
      donation_level {
        donation_amount
        rewards {
          richText
        }
        donation_title {
          text
        }
      }
      content_below_donation_levels {
        richText
      }
    }
  }
}
`

export default Donate
