import React, { useState, useRef } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    VStack,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    HStack,
    Flex,
    Spinner,
    Heading,
  } from "@chakra-ui/react";
  import { BsXCircle, BsCheck2Circle } from "react-icons/bs";
  import { VscWarning } from "react-icons/vsc";
import { useEffect } from 'react';

  const schema = yup.object().shape({
    donorName: yup.string().required("Name is required"),
    email: yup.string().email("Please enter a valid email").required("Email is required"),
    phone: yup.string().required("Phone is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    zip: yup.string().required("Zip is required"),
  }
  );

export default function DonationForm(props) {

    const {
        register,
        watch,
        control,
        handleSubmit,
        reset,
        formState: { errors, isValid },
      } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
      });

    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [amount, setAmount] = useState(props.amount);

    const stripe = useStripe();
    const elements = useElements();

    const watchObject = watch();

    console.log(amount)

    const errorStyles = {
        color: "#bf1650",
        fontSize: "1rem",
      };
      
    function RequiredSpan() {
      return <span style={{ color: "#bf1650" }}>*</span>;
    }

    // create a Ref to access our form element
   const formRef = useRef(null)

    const stripeStyle = {
        base: {
          color: "#134053",
          fontFamily: "Open Sans, sans-serif",
          fontSize: "1.25rem",
        },
        invalid: {
          color: "#bf1650",
          iconColor: "#bf1650",
        },
      };

         // Show any Stripe errors
   const handleChange = async (event) => {
    setError(event.error ? event.error.message : "");
  };
      
  const FormSuccess = () => {
    return (
      <Box my="20">
        <VStack>
          <BsCheck2Circle size="4rem" color='#3a2152'/>
          <Heading as="h2" textAlign="left" my="4" color='#3a2152'>
            Thank you for your donation!
          </Heading>
        </VStack>
      </Box>
    );
  };


   const sendFormData = async (event) => {
              
       if (!formRef.current) {
           console.log('something went wrong with the ref')
       }

       const formData = new FormData(formRef.current)
       
       console.log(Array.from(formData.entries()))

       setProcessing(true);

       window.fetch("/.netlify/functions/stripe-donation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount,
          receipt_email: watchObject.email,
          description: "Grizzyly Bear 399 Donation",
          metadata: {
            donorName: watchObject.donorName,
            email: watchObject.email,
            phone: watchObject.phone,
            address: watchObject.address,
            city: watchObject.city,
            state: watchObject.state,
            zip: watchObject.zip,
          },
        }),
      })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setClientSecret(data.clientSecret);
        console.log(data);
        return stripe
          .confirmCardPayment(data.clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement),
            },
          })

          .then((paymentResult) => {
            if (paymentResult.error) {
              setError(`Payment failed: ${paymentResult.error.message}`);
              setProcessing(false);
            } else {
              if (paymentResult.paymentIntent.status === "succeeded") {
                setError(null);
                setSucceeded(true)
                setProcessing(false)
                reset()
              }
            }
          })
      })
}

    return (
        <>
            { succeeded ? <FormSuccess /> : 
            
            <div>
                <form id="donation-form" onSubmit={handleSubmit(sendFormData)} onChange={handleChange} ref={formRef}>
                
                {/* <Box mb="8" width="50%">
                    <FormControl>
                        <FormLabel>
                            Enter other amount: 
                        </FormLabel>
                        <Input
                            type="number"
                            name="amount"
                            placeholder="Enter amount"
                            {...register("amount")}
                            onChange={event => setAmount(parseInt(event.target.value))}
                            />
                    </FormControl>
                </Box> */}


                <Box textAlign="left" mb="8">
                    <FormControl>
                        <FormLabel>
                        Name:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                        //size="sm"
                        type="text"
                        {...register("donorName")}
                        autoComplete="on"
                        />

                        {errors.donorName && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.donorName?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                </Box>

                <Flex w="100%" flexWrap="wrap">
                <Box textAlign="left" mb="8" w={{base: '100%', lg: '50%'}} pr={{base: 0, lg: 2}}>
                    <FormControl>
                        <FormLabel>
                        Email:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                        //size="sm"
                        type="text"
                        {...register("email")}
                        autoComplete="on"
                        />

                        {errors.email && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.email?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                </Box>
                <Box textAlign="left" mb="8" w={{base: '100%', lg: '50%'}} pl={{base: 0, lg: 2}}>
                    <FormControl>
                        <FormLabel>
                        Phone:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                        //size="sm"
                        type="text"
                        {...register("phone")}
                        autoComplete="on"
                        />

                        {errors.phone && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.phone?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                </Box>
                </Flex>

                <Box textAlign="left" mb="8">
                    <FormControl>
                        <FormLabel>
                        Street Address:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                        //size="sm"
                        type="text"
                        {...register("address")}
                        autoComplete="on"
                        />

                        {errors.address && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.address?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                </Box>

                <Flex w="100%" flexWrap="wrap">
                <Box textAlign="left" mb="8" w={{base: '100%', lg: '33%'}} pr={{base: 0, lg: 2}}>
                    <FormControl>
                        <FormLabel>
                        City:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                        //size="sm"
                        type="text"
                        {...register("city")}
                        autoComplete="on"
                        />

                        {errors.city && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.city?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                </Box>
                <Box textAlign="left" mb="8" w={{base: '100%', lg: '33%'}}>
                    <FormControl>
                        <FormLabel>
                        State:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                        //size="sm"
                        type="text"
                        {...register("state")}
                        autoComplete="on"
                        />

                        {errors.state && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.state?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                </Box>
                <Box textAlign="left" mb="8" w={{base: '100%', lg: '33%'}} pl={{base: 0, lg: 2}}>
                    <FormControl>
                        <FormLabel>
                        Zip:
                        <RequiredSpan />
                        </FormLabel>
                        <Input
                        //size="sm"
                        type="text"
                        {...register("zip")}
                        autoComplete="on"
                        />

                        {errors.zip && (
                        <HStack spacing="5px" style={errorStyles}>
                            <VscWarning /> <Box>{errors.zip?.message}</Box>
                        </HStack>
                        )}
                    </FormControl>
                </Box>
                </Flex>

                <Box mb="8">
                <FormLabel>
                        Credit Card Information:
                        <RequiredSpan />
                        </FormLabel>
                    <CardElement options={{ style: stripeStyle }} />
                </Box>
                <Box my="6">
              <Button type="submit" textAlign={{base: "center", md: "left"}} disabled={processing}>
                {processing ? (
                  <Spinner />
                ) : succeeded ? (
                  "Submitted!"
                ) : (
                  <>
                    Donate ${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </>
                )}
              </Button>
              {error && (
                  <div style={errorStyles} role="alert">
                    <HStack spacing="5px">
                      <VscWarning/><p className="card-error">{error}</p> 
                    </HStack>
                  </div>
                )}
            </Box>
                </form>
            </div>
            }
        </>
    )
}
