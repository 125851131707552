import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import DonationForm from './DonationForm';

//const promise = loadStripe('pk_test_51KdHVWGBu8YmsAZwNQxAhH1gnUg7TEVvs9C3PJSltdQ4snm976x000UbaLJaoSArtITNjwwbgHzXsV7GPcgrudLS00OkMXHYwX');
const promise = loadStripe('pk_live_51KdHVWGBu8YmsAZwOYNmP6JOsKUVXHLMqez3U0ZBnan9RDD1cdXR2KRQIOQwCFveruviUAIkTKG02sOPcenTk3Fy00yBtnGEQC');

export default function DonationWrapper(props) {
    return (
        <div>
            <Elements stripe={promise}>
                <DonationForm amount={props.amount} />
            </Elements>
        </div>
    )
}